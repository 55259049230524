import cheekKissImg from "../../../assets/images/competitions/optimised/lg/cheekkiss-collection.png";
import ghostImg from "../../../assets/images/competitions/optimised/lg/ghost-xmas-collection.png";
import dknyImg from "../../../assets/images/competitions/optimised/lg/dkny-247.png";
// smaller images - unused
import britneySm from "../../../assets/images/competitions/optimised/sm/climaplex-box.png";
import ghostSm from "../../../assets/images/competitions/optimised/sm/wetnwild.png";

export const ImageVariants = {
    1: {
        smallImage: britneySm,
        largeImage: cheekKissImg,
    },
    2: {
        smallImage: ghostSm,
        largeImage: ghostImg,
    },
    3: {
        smallImage: ghostSm,
        largeImage: dknyImg,
    }
};
