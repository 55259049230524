import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useIsMobile from "../../lib/hooks/useIsMobile";
import Container from "../common/container/Container";
import CompetitionDataContainer from "../ui/cards/CompetitionDataContainer";
import TsContainer from "../common/container/TsContainer";
import "../common/container/TsContainer.css";
import "./Competitions.css";
import Card from "../ui/cards/Card";

const Competitions = ({ sectionRefs }) => {
  const isMobile = useIsMobile();
  const screenWidth = window.innerWidth;

  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRefs && sectionRef.current) {
      sectionRefs.current.push(sectionRef);
    }
  }, [sectionRefs, sectionRef]);

  return (
      <section
          ref={sectionRef}
          className="section competitions flex flex-col justify-center items-center md:my-24 xl:mt-32"
          id="competitions"
      >
          <TsContainer>
              <div className="flex flex-col justify-center md:justify-start">
                  <h1 className="comp__header text-5xl md:text-6xl lg:text-7xl font-thin mb-8 mx-6 sm:mx-2 md:mx-0">
                      <span>
                          Win <b>BIG</b> with
                      </span>
                      <br />
                      {isMobile && screenWidth < 768 && screenWidth >= 700 && (
                          <br className="hidden lg:inline" />
                      )}
                      The Edit
                  </h1>

                  <h2 className="comp__tag text-lg md:text-xl lg:text-2xl font-extralight mx-6 sm:mx-2 md:mx-0 md:w-4/5 lg:w-3/5">
                      Get your hands on some of the best makeup, fragrances and
                      more,{" "}
                      {!isMobile && screenWidth <= 1024 && (
                          <span>
                              <br />
                          </span>
                      )}
                      for free with our great giveaways, below.
                  </h2>
              </div>
          </TsContainer>
          <Container>
              {/* Competition Cards Data Container */}
              <CompetitionDataContainer />
          </Container>
          {/* Terms and conditions updated as own card */}
          <TsContainer className="container flex justify-center">
              <Card className="flex flex-col justify-center items-center m-8 md:m-0 mt-16 md:mt-16">
                  <h2 className="text-3xl text-center md:text-4xl md:text-left">Terms & conditions</h2>
                  <br />
                  <p className="text-center">
                  No purchase is necessary to enter the Prize Draws. Entries must be received by 23:59 on 31/12/2024. 
                  By entering the Prize Draws, all entrants agree that their personal data will be used by The Edit in accordance with The Edit’s{" "}
                      <a
                          href="/pdf/The-Edit-Privacy-Notice-V2.pdf"
                          target="_blank"
                          className="mr-4 underline md:mr-6 hover:text-sd-aqua"
                          rel="noreferrer"
                      >
                          Privacy Policy
                      </a>
                  </p>
              </Card>
          </TsContainer>
      </section>
  );
};

Competitions.propTypes = {
  sectionRefs: PropTypes.object,
};

export default Competitions;
